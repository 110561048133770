import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import { graphql, navigate, useStaticQuery } from "gatsby"

import Layout from "../Layout/Layout"
import Container from "../Layout/Container"

const AuthSuccess = ({ location }) => {
  const locationState = location.state
  const title = locationState?.title || ""
  const seoTitle = locationState?.seoTitle || ""
  const message = locationState?.message || ""
  const successUrl = locationState?.successUrl || ""
  const [timeRemaining, setTimeRemaining] = useState(5)

  const data = useStaticQuery(graphql`
    {
      spilledMeds: file(relativePath: { eq: "pages/auth__success.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const spilledMeds = data.spilledMeds.childImageSharp.fluid

  useEffect(() => {
    if (timeRemaining > 1)
      setTimeout(() => {
        setTimeRemaining(timeRemaining - 1)
      }, 1000)
    else window.open(successUrl, "_self")
  }, [timeRemaining])

  useEffect(() => {
    if (!successUrl) navigate("/")
  }, [])

  if (successUrl)
    return (
      <Layout seoTitle={seoTitle}>
        <Container customClassName="my-3" isCentered>
          <Container mobile={10} tablet={8} desktop={6} isCentered>
            <Img fluid={spilledMeds} />
          </Container>
          <center>
            <h2>{title}</h2>
            <p>{message}</p>
            <p className="mt-2">
              If you are not redirected in {timeRemaining} second
              {timeRemaining > 1 ? "s" : ""}, please click{" "}
              <a href={successUrl}>this link</a>.
            </p>
          </center>
        </Container>
      </Layout>
    )
  return null
}

export default AuthSuccess
